import { ICookie } from "@/common/interfaces/index.interface";

export const essentialCookiesCategoryName = `necessary`;

const cookies: ICookie[] = [
  {
    active: true,
    cookies: [
      {
        active: true,
        cookieNames: `ccn-interacted`,
        duration: `1 Jahr`,
        explanation: `Dieses Cookie speichert, dass Sie die Cookies bestätigt haben und welche Cookies Sie erlaubt haben.`,
        name: `ccn-interacted`,
        privacy: ``,
        responsible: `Eigentümer dieser Website`,
        title: `Cookie-Notice`,
        value: true,
      },
      {
        active: true,
        cookieNames: `fe_typo_user`,
        duration: `Session`,
        explanation: `Dieses Cookie speichert keine persönlichen Daten. Es ermöglicht ausschließlich den Login. (Session-Cookie)`,
        name: `fe_typo_user`,
        privacy: ``,
        responsible: `Eigentümer dieser Website`,
        title: `Typo3-Session`,
        value: true,
      },
      {
        active: true,
        cookieNames: `uhs_js_aktiv`,
        duration: `Session`,
        explanation: `Dieses Cookie wird benötigt um dem System mitzuteilen, das Javascript aktiv ist.`,
        name: `Session`,
        privacy: ``,
        responsible: `Eigentümer dieser Website`,
        title: `Javascript-enabled`,
        value: true,
      },
    ],
    explanation: `Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.`,
    indeterminate: false,
    name: `necessary`,
    title: `Essenziell`,
  },
  {
    active: false,
    cookies: [
      {
        active: false,
        cookieNames: `MATOMO_SESSID, _pk_id, _pk_ref, _pk_ses, _pk_cvar, _pk_hsr, mtm_consent`,
        duration: `14 Tage (MATOMO_SESSID), 13 Monate (_pk_id), 6 Monate (_pk_ref), 30 Minuten (_pk_ses, _pk_cvar, _pk_hsr), 30 Jahre (mtm_consent)`,
        explanation: `(Information über die Zustimmung oder Ablehnung der Cookie Einstellungen werden über einen langen Zeitraum gespeichert.)`,
        name: `mato`,
        privacy: `https://matomo.org/privacy-policy/`,
        responsible: `Eigentümer dieser Website`,
        title: `Statistik & Analyse (Matomo Analytics)`,
        value: true,
      },
    ],
    explanation: `Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.`,
    indeterminate: false,
    name: `statistic`,
    title: `Statistiken`,
  },
];

export default cookies;
