import { render, staticRenderFns } from "./CookieNotice.vue?vue&type=template&id=ca626e7c&scoped=true&"
import script from "./CookieNotice.vue?vue&type=script&lang=ts&"
export * from "./CookieNotice.vue?vue&type=script&lang=ts&"
import style0 from "./CookieNotice.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CookieNotice.vue?vue&type=style&index=1&id=ca626e7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca626e7c",
  null
  
)

export default component.exports