import { ITexts } from "@/common/interfaces/index.interface";

const texts: ITexts = {
  buttons: {
    acceptAll: `Alle akzeptieren`,
    declineAll: `Alle ablehnen`,
    saveSelection: `Auswahl speichern`,
    showPreferences: `Präferenzen aktualisieren`,
  },
  cookieDetails: {
    cookieNames: `Namen der Cookies`,
    duration: `Laufzeit der Cookies`,
    privacy: `Datenschutzerklärung`,
    responsible: `Anbieter`,
  },
  links: [
    {
      openPreferences: true,
      text: `Mehr Informationen`,
    },
    {
      href: `https://static.kfv.at/datenschutz/`,
      openPreferences: false,
      text: `Datenschutzerklärung`,
    },
    {
      href: `/impressum`,
      openPreferences: false,
      text: `Impressum`,
    },
  ],
  notice: {
    headline: `DIESE WEBSITE VERWENDET COOKIES`,
    text: `Einige davon sind technisch notwendig, andere erleichtern Ihnen die Nutzung oder helfen uns, Inhalte und Anzeigen zu personalisieren,
    Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Webseite zu analysieren. Außerdem geben wir Informationen zu
    Ihrer Verwendung unserer Webseite an unsere Partner für soziale Medien, Werbung und Analysen weiter.
`,
  },
  preferences: {
    explanation: `Hier finden Sie eine Übersicht über alle verwendeten Cookies.
    Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen.`,
    headline: `DATENSCHUTZEINSTELLUNGEN`,
    listCookies: `Cookies auflisten`,
  },
};

export default texts;
